import {DOCUMENT} from '@angular/common';
import {Inject, Injectable} from '@angular/core';
import {BehaviorSubject, Observable, skip, Subscription, tap, withLatestFrom} from 'rxjs';
import {LocalStorage} from 'src/app/enums/core/local-storage.enum';
import {Theme} from 'src/app/enums/core/theme.enum';
import {ProfileFacade} from 'src/app/facades/profile.facade';
import {DEFAULT_PORTAL_THEME} from 'src/app/helpers/core/site-config.helper';
import {UserSettings} from 'src/app/interfaces/core/user-settings.interface';

@Injectable({
    providedIn: 'root'
})
export class ThemeService {
    public theme$: Observable<Theme>;

    private userSettings$: Observable<UserSettings | null>;
    private _theme$: BehaviorSubject<Theme> = new BehaviorSubject<Theme>(DEFAULT_PORTAL_THEME);
    private subscriptions: Subscription = new Subscription();

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private profileFacade: ProfileFacade
    ) {
        this.theme$ = this._theme$.asObservable();
        this.userSettings$ = this.profileFacade.userSettings$;
        this.subscriptions.add(this.watchForUserSettings());
    }

    private getTheme() {
        return this._theme$.getValue();
    }

    private setTheme(value?: Theme) {
        this._theme$.next(value ?? DEFAULT_PORTAL_THEME);
    }

    private watchForUserSettings() {
        return this.userSettings$.pipe(
            skip(1),
            withLatestFrom(this.theme$),
            tap(([userSettings, currentTheme]) => {
                const newTheme = userSettings?.theme ?? this.getThemeFromLocalStorage() ?? DEFAULT_PORTAL_THEME;
                if (currentTheme === newTheme) return;

                this.changeThemeLogic(newTheme);
            })
        ).subscribe();
    }

    public changeTheme() {
        const currentTheme = this.getTheme();
        const theme = currentTheme === DEFAULT_PORTAL_THEME ? Theme.LIGHT : DEFAULT_PORTAL_THEME;
        this.changeThemeLogic(theme);
        const userSettings: UserSettings = {theme};
        this.profileFacade.updateUserSettings(userSettings);
    }

    private changeThemeLogic(theme: Theme) {
        const newThemeLink = document.createElement('link');
        newThemeLink.setAttribute('rel', 'stylesheet');
        newThemeLink.setAttribute('type', 'text/css');
        newThemeLink.setAttribute('href', `${theme}.css`);
        newThemeLink.addEventListener('load', () => {
            const themeLink = this.document.getElementById(`app-theme`) as HTMLLinkElement;
            themeLink?.remove();
            newThemeLink.setAttribute('id', `app-theme`);
        }, false);
        this.document.head.append(newThemeLink);
        this.setTheme(theme);
        this.saveThemeToLocalStorage(theme);
    }

    private saveThemeToLocalStorage(theme: Theme) {
        localStorage.setItem(LocalStorage.THEME, theme);
    }

    public getThemeFromLocalStorage() {
        const theme = localStorage.getItem(LocalStorage.THEME);
        return theme ? theme as Theme : null;
    }
}